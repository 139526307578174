<template>
  <div>
    <div class="box">
      <Topbar></Topbar>
      <SuS></SuS>
      <div class="home-swiper" @mouseleave="noselect()">
        <div class="left-list">
          <div
            :class="[isActive == index ? 'left-list-item2' : ' left-list-item']"
            v-for="(item, index) in categoryList2"
            :key="index"
            @mouseenter="select(item, index)"
          >
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
        <!-- swiper -->
        <div class="banner" v-if="nobanner">
          <el-carousel class="" trigger="click" height="400px">
            <el-carousel-item v-for="item in banners" :key="item.Id">
              <img
                v-lazy="item.ImgUrl"
                @click="bannnerGoTo(item)"
                alt="item.Name"
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="sele" v-else>
          <div class="menu">
            <div
              class="outside-item"
              v-for="(item, index) in twoarr"
              :key="index"
            >
              <!-- 
              @mouseenter="select2(index)"
                :style="{ color: isActive2 == index ? '#FC4B24' : '#333333' }" -->
              <div class="title" @click="twolist(item.id)">
                {{ item.text }}
              </div>
              <div class="item-img">
                <img src="../../assets/images/menu-left.png" alt="" />
              </div>

              <div class="all">
                <div
                  class="content"
                  :key="i"
                  v-for="(it, i) in twoarr[index].children"
                >
                  <!-- 
                  @mouseenter="select3(i, index)"
                  :style="{
                    color:
                      isActive3 == i && outsideIndex == index
                        ? '#FC4B24'
                        : '#333333',
                  }" -->
                  <div class="titleText" @click="twolist(it.id)">
                    {{ it.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 热门推荐 -->
      <div class="recommend">
        <div class="recommend-font">热门推荐</div>
        <div class="recommend-whole">
          <div class="" @click="togoodslist()">查看全部</div>
          <img src="../../assets/images/tuijian.png" alt="" />
        </div>
      </div>

      <div class="recommend-body">
        <div
          class="recommend-body-item"
          v-for="(item, index) in homeHotList"
          :key="index"
          @click="togoodsdetail(item.Id)"
        >
          <div class="recommend-body-img">
            <img :src="item.CoverImg" alt="" />
          </div>
          <div class="recommend-noimg">
            <div class="recommend-body-font">{{ item.GoodsName }}</div>
            <div class="recommend-price">
              <div class="money">
                <div class="money-icon">￥</div>
                <div class="money-num">{{ item.DefaultPrice }}</div>
              </div>
              <div class="money-people">{{ item.BuyCount }}人付款</div>
            </div>
            <!-- <div class="recommend-box">
              <div class="juan">券</div>
              <div class="off">
                满{{ item.FullMoeny }}减{{ item.CouponMoeny }}
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="recommend">
        <div class="recommend-font">为你推荐</div>
        <div class="recommend-whole">
          <div class="" @click="togoodslist()">查看全部</div>
          <img src="../../assets/images/tuijian.png" alt="" />
        </div>
      </div>

      <div class="your">
        <div
          :class="[
            yourindex == index
              ? 'recommend-body-item2'
              : ' recommend-body-item',
          ]"
          v-for="(item, index) in yourlist"
          :key="index"
          @click="togoodsdetail(item.Id)"
          @mousemove="change6(index)"
        >
          <div class="recommend-body-img">
            <img :src="item.CoverImg" alt="" />
          </div>
          <div class="recommend-noimg">
            <div class="recommend-body-font">{{ item.GoodsName }}</div>
            <div class="recommend-price">
              <div class="money">
                <div class="money-icon">￥</div>
                <div class="money-num">{{ item.DefaultPrice }}</div>
              </div>
              <div class="money-people">{{ item.BuyCount }}人付款</div>
            </div>
            <!-- <div class="recommend-box">
              <div class="juan">券</div>
              <div class="off">
                满{{ item.FullMoeny }}减{{ item.CouponMoeny }}
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="type">
        <div
          :class="[isActive == index ? 'type-item' : ' type-item2']"
          v-for="(item, index) in categoryList"
          :key="index"
          @click="change(index, item)"
        >
          {{ item.text }}
        </div>
      </div>

      <!-- 列表 -->
      <div class="center" v-if="typeshoplist.length != 0">
        <div
          :class="[item.ischeck ? 'center-item2' : ' center-item']"
          v-for="(item, index) in typeshoplist"
          :key="index"
          @click="togoodsdetail(item.Id)"
          @mouseenter="change4(item)"
          @mouseleave="change5(item)"
        >
          <img :src="item.CoverImg" alt="" />
          <div class="center-item-font">
            {{ item.GoodsName }}
          </div>
          <div class="center-price">
            <div class="center-price-money">￥</div>
            <div class="center-price-price">{{ item.DefaultPrice }}</div>
            <div class="center-price-people">{{ item.BuyCount }}人付款</div>
          </div>
          <!-- <div class="pre">
            <div class="pre-one">劵</div>
            <div class="pre-two">
              满{{ item.FullMoeny }}减{{ item.CouponMoeny }}
            </div>
          </div> -->
        </div>
      </div>
      <div v-if="typeshoplist.length == 0">
        <nocontent name="暂无商品"></nocontent>
      </div>
    </div>
    <Foter></Foter>
  </div>
</template>
<script>
import Topbar from '../PCshop/components/topbar.vue'
import SuS from '../PCshop/components/suspen.vue' //悬浮
import Foter from '../PCshop/components/shopfooter.vue'
import nocontent from '../shop/components/nocontent.vue'
import { qeryShopGoodsList, queryGoodsCategoryTree, queryPcbannerList, queryShopCartList } from "@/api/shop";
export default {
  components: {
    Topbar, SuS, Foter, nocontent
  },
  data () {
    return {
      yourindex: 0,
      outsideIndex: 0,
      userInfo: {},
      pageIndex: 1,
      pageSize: 10,
      isActive: '',//判断选中
      isActive2: '',
      isActive3: 1000,
      nobanner: true,
      twoarr: [],
      goodsHotList: [],//热门
      homeHotList: [],//首页热门
      banners: [],
      yourlist: [ //为你推荐

      ],
      typelist: [
        {
          text: '艺术品',
          ischeck: true,
        },
        {
          text: '文创品',
          ischeck: false,
        },
        {
          text: '画具画材',
          ischeck: false,
        },
        {
          text: '教材图书',
          ischeck: false,
        },
        {
          text: '考试必备',
          ischeck: false,
        },
      ],
      imgs: [
        {
          icon: require('../../assets/PcSHOP/pchome1.png'),
          iconselect: require('../../assets/PcSHOP/pchome-select1.png')
        },
        {
          icon: require('../../assets/PcSHOP/pchome2.png'),
          iconselect: require('../../assets/PcSHOP/pchome-select2.png')
        },
        {
          icon: require('../../assets/PcSHOP/pchome3.png'),
          iconselect: require('../../assets/PcSHOP/pchome-select3.png')
        },
        {
          icon: require('../../assets/PcSHOP/pchome4.png'),
          iconselect: require('../../assets/PcSHOP/pchome-select4.png')
        },
        {
          icon: require('../../assets/PcSHOP/pchome5.png'),
          iconselect: require('../../assets/PcSHOP/pchome-select5.png')
        },
      ],
      typeshoplist: [

      ],
      goodsRecommendList: [],
      categoryList: [],
      categoryList2: []
    }
  },
  created () {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
    this.getQueryShopCartList()//购物车数量
    this.getCommonQueryBannerList() //轮播
    this.postQeryShopGoodsList() //列表
    this.postQeryShopGoodsCategoryList()//分类
    this.postQeryHotShopGoodsList()//热门
    this.postTypeShopGoodsList() //底部
  },
  methods: {
    // banner跳转
    bannnerGoTo (obj) {
      if (obj.GoodsId) {
        this.$router.push({ name: 'PCshop/goods/goodsdetail', query: { id: obj.GoodsId } });
      }
    },
    // 一进入首页就请求购物车数量并保存
    // 购物车列表数据
    async getQueryShopCartList () {
      let parm = {};
      parm.StudentId = this.userInfo.Id;
      const res = await queryShopCartList(parm);
      this.shopcarlength = res.response.dataCount //购物车数量
      console.log(this.shopcarlength)
      this.$store.commit('changeshopcar', this.shopcarlength)
      // localStorage.setItem('shopnum', this.shopcarlength);
      this.isLoad = false;
      if (res.response.data.length == 0) {
        this.isEnd = true;
      }
    },
    // 请求轮播图
    async getCommonQueryBannerList () {
      let parm = "?pageIndex=" + this.pageIndex + "&pageSize=" + this.pageSize + "&category=1";
      const res = await queryPcbannerList(parm);
      console.log(res, 456)
      if (res.success == true) {
        this.banners = res.response.data;
      } else {
        this.$message.error(res.msg);
      }
    },
    // 为你推荐列表
    async postQeryShopGoodsList () {
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.IsRecommend = 1;
      const res = await qeryShopGoodsList(parm);
      this.goodsRecommendList = this.goodsRecommendList.concat(res.data);
      this.yourlist = this.goodsRecommendList.splice(0, 3)
      this.isLoad = false;
      if (res.data.length == 0) {
        this.isEnd = true;
      }
    },
    // 热门列表
    async postQeryHotShopGoodsList () {
      let parm = {};
      parm.pageIndex = 1;
      parm.pageSize = this.pageSize;
      parm.IsHot = 1;
      const res = await qeryShopGoodsList(parm);
      this.goodsHotList = res.data;
      console.log(this.goodsHotList, 888)
      this.homeHotList = this.goodsHotList.splice(0, 4)

      this.isLoad = false;
    },
    //分类列表数据
    async postQeryShopGoodsCategoryList () {
      let parm = {};
      const res = await queryGoodsCategoryTree(parm);
      this.categoryList = res.response.slice(0, 6);
      this.categoryList2 = res.response
      this.twoarr = this.categoryList[0].children
    },

    // 最下面列表数据
    async postTypeShopGoodsList (typeid) {
      // console.log(typeid, 789789797889798789)
      let parm = {};
      parm.pageIndex = this.pageIndex;
      parm.pageSize = this.pageSize;
      parm.IsRecommend = 1;
      typeid == undefined ? parm.CategoryIds = ['3'] : parm.CategoryIds = [typeid]
      // parm.CategoryId=typeid;
      const res = await qeryShopGoodsList(parm);
      this.typeshoplist = res.data;
      // console.log(this.typeshoplist, 78978979)
      this.isLoad = false;
      if (res.data.length == 0) {
        this.isEnd = true;
      }
    },
    change (idx, itm) {
      this.isActive = idx
      console.log(itm.id);
      this.postTypeShopGoodsList(itm.id)
    },
    // 鼠标放到列表上加阴影以及放大
    change4 (itm) {
      this.$set(itm, 'ischeck', 'true')
    },
    change5 (itm) {
      this.$delete(itm, 'ischeck', 'true')
    },
    change6 (idx) {
      this.yourindex = idx
    },
    togoodsdetail (id) {
      // console.log(id)
      this.$router.push({ name: 'PCshop/goods/goodsdetail', query: { id: id } });
    },
    // 鼠标移入top选择菜单
    select (itm, idx) {
      this.isActive = idx
      this.nobanner = false
      this.twoarr = this.categoryList2[idx]?.children ? this.categoryList2[idx].children : []
    },
    select2 (idx) {
      this.isActive2 = idx
    },
    select3 (idx, outsideIndex) {
      console.log(idx)
      this.isActive3 = idx
      this.outsideIndex = outsideIndex;
    },
    twolist (itm) {
      this.$router.push({
        name: `PCshop/goods/list`,
        params: {
          categoryId: itm
        }
      })
    },
    // 鼠标移出top选择菜单
    noselect () {
      this.nobanner = true
      this.zhenshilist = [];
    },
    // 跳转列表页
    togoodslist () {
      this.$router.push({
        path: `/PCshop/goods/list`,
      })
    },
    //   跳转到商品详情页
    togoodsdetail (id) {
      console.log(id)
      this.$router.push({ path: '/PCshop/goods/goodsdetail', query: { id: id } });
      //   {path: '/backend/order', query: {selected: "2"}}
    },
  }
}
</script>
<style lang="scss" scoped>
.box {
  background: #f5f5f5;
  overflow: hidden;
}
.home-swiper {
  width: 1100px;
  margin: 20px auto;
  height: 400px;
  display: flex;
  justify-content: space-between;
  .left-list {
    cursor: pointer;
    .icon {
      position: absolute;
    }
    width: 190px;
    height: 400px;
    background: #f7462c;
    opacity: 0.8;
    //  padding: 12px 17px 0px 16px;
    .left-list-item {
      cursor: pointer;
      height: 44px;
      line-height: 47px;
      border-bottom: 1px solid #f9b4ba;
      display: flex;
      background-image: url("../../assets/images/shouyeleft.png");
      background-repeat: no-repeat;
      background-position: 92% 55%;
      //  padding-left: 40px;
      .text {
        cursor: pointer;
        font-size: 14px;
        color: #ffffff;
        margin-left: 15px;
      }
      .icon {
        width: 16px;
        height: 16px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .left-list-item2 {
      cursor: pointer;
      height: 44px;
      line-height: 47px;
      border-bottom: 1px solid #f35332;
      display: flex;
      background-image: url("../../assets/images/shouyeleft2.png");
      background-repeat: no-repeat;
      background-position: 92% 25%;
      background: #ffffff;
      .text {
        cursor: pointer;
        font-size: 14px;
        color: #f7462c;
        margin-left: 15px;
      }
      .icon {
        width: 16px;
        height: 16px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .banner {
    height: 400px;
    width: 892px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .menu {
    height: 400px;
    width: 892px;
    background: #ffffff;
  }
}
.recommend {
  width: 1100px;
  margin: 40px auto 20px;
  display: flex;
  justify-content: space-between;
  .recommend-font {
    font-size: 22px;
    color: #333333;
    font-weight: bold;
  }
  .recommend-whole {
    cursor: pointer;
    display: flex;
    width: 81px;
    height: 19px;
    font-size: 14px;
    color: #999999;
    font-weight: 400px;
    img {
      width: 6px;
      height: 10px;
      margin-left: 15px;
      margin-top: 3px;
    }
  }
}
.recommend-body {
  width: 1100px;
  height: 340px;
  background: #ffffff;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 10px;
  .recommend-body-item {
    cursor: pointer;
    display: flex;
    width: 520px;
    height: 140px;
    background: #f5f5f5;
    margin-top: 10px;
    padding: 20px 52px 20px 15px;
    box-sizing: border-box;
    .recommend-body-img {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .recommend-noimg {
      margin-left: 15px;
      .recommend-body-font {
        width: 338px;
        height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        font-size: 14px;
        color: #333333;
        font-weight: 500;
      }
      .recommend-price {
        display: flex;
        .money {
          display: flex;
          .money-icon {
            color: #f40707;
            font-size: 12px;
            margin-top: 8px;
          }
          .money-num {
            color: #f40707;
            font-size: 20px;
          }
        }
        .money-people {
          font-size: 12px;
          color: #999999;
          margin-left: 8px;
          margin-top: 5px;
        }
      }
      .recommend-box {
        margin-top: 9px;
        display: flex;
        .juan {
          width: 26px;
          height: 16px;
          border: 1px solid #e1251b;
          font-size: 11px;
          border-radius: 2px;
          text-align: center;
          line-height: 16px;
          color: #e1251b;
        }
        .off {
          width: 68px;
          height: 16px;
          border: 1px solid #e1251b;
          font-size: 11px;
          border-radius: 2px;
          text-align: center;
          line-height: 16px;
          color: #e1251b;
          margin-left: 6px;
        }
      }
    }
  }
}

.your {
  width: 1100px;
  height: 140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  .recommend-body-item {
    cursor: pointer;
    display: flex;
    width: 352px;
    height: 140px;
    background: #ffffff;
    margin-top: 10px;
    padding: 20px 14px 20px 15px;
    box-sizing: border-box;
    .recommend-body-img {
      img {
        width: 100px;
        height: 100px;
      }
    }
    .recommend-noimg {
      margin-left: 15px;
      .recommend-body-font {
        height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-size: 14px;
        color: #333333;
        font-weight: 500;
      }
      .recommend-price {
        margin-top: 15px;
        display: flex;
        .money {
          display: flex;
          .money-icon {
            color: #f40707;
            font-size: 12px;
            margin-top: 8px;
          }
          .money-num {
            color: #f40707;
            font-size: 20px;
          }
        }
        .money-people {
          font-size: 12px;
          color: #999999;
          margin-left: 8px;
          margin-top: 9px;
        }
      }
      .recommend-box {
        margin-top: 9px;
        display: flex;

        .juan {
          width: 26px;
          height: 16px;
          border: 1px solid #e1251b;
          font-size: 11px;
          border-radius: 2px;
          text-align: center;
          line-height: 16px;
          color: #e1251b;
        }
        .off {
          width: 68px;
          height: 16px;
          border: 1px solid #e1251b;
          font-size: 11px;
          border-radius: 2px;
          text-align: center;
          line-height: 16px;
          color: #e1251b;
          margin-left: 6px;
        }
      }
    }
  }
  .recommend-body-item2 {
    box-shadow: 10px 10px 8px rgba(184, 184, 184, 0.5);
    cursor: pointer;
    display: flex;
    width: 352px;
    height: 140px;
    background: #ffffff;
    margin-top: 10px;
    padding: 20px 14px 20px 15px;
    box-sizing: border-box;
    .recommend-body-img {
      img {
        width: 100px;
        height: 100px;
      }
    }
    .recommend-noimg {
      margin-left: 15px;
      .recommend-body-font {
        height: 20px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        font-size: 14px;
        color: #333333;
        font-weight: 500;
      }
      .recommend-price {
        margin-top: 15px;
        display: flex;
        .money {
          display: flex;
          .money-icon {
            color: #f40707;
            font-size: 12px;
            margin-top: 8px;
          }
          .money-num {
            color: #f40707;
            font-size: 20px;
          }
        }
        .money-people {
          font-size: 12px;
          color: #999999;
          margin-left: 8px;
          margin-top: 9px;
        }
      }
      .recommend-box {
        margin-top: 9px;
        display: flex;

        .juan {
          width: 26px;
          height: 16px;
          border: 1px solid #e1251b;
          font-size: 11px;
          border-radius: 2px;
          text-align: center;
          line-height: 16px;
          color: #e1251b;
        }
        .off {
          width: 68px;
          height: 16px;
          border: 1px solid #e1251b;
          font-size: 11px;
          border-radius: 2px;
          text-align: center;
          line-height: 16px;
          color: #e1251b;
          margin-left: 6px;
        }
      }
    }
  }
}

.type {
  cursor: pointer;
  width: 1100px;
  margin: 40px auto 20px;
  display: flex;
  .type-item {
    padding: 0px 10px;
    height: 30px;
    color: #ffffff;
    background: #fa4926;
    margin-left: 38px;
    text-align: center;
    line-height: 30px;
    border-radius: 20px;
    font-weight: bold;
  }
  .type-item2 {
    padding: 0px 10px;
    height: 30px;
    color: #333333;
    margin-left: 38px;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
  }
}
.type :first-child {
  margin-left: 0px;
}

// 列表
.center {
  width: 1100px;
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto 30px;
  .center-item {
    margin-right: 15px;
    margin-top: 20px;
    width: 204px;
    background: #ffffff;
    padding: 28px 27px 20px 27px;
    box-sizing: border-box;
    img {
      width: 150px;
      height: 150px;
    }
    .center-item-font {
      margin-top: 30px;
      width: 168px;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .center-price {
      display: flex;
      margin-top: 14px;
      // justify-content: space-between;
      .center-price-money {
        color: #f40707;
        font-size: 12px;
        margin-top: 5px;
      }
      .center-price-price {
        color: #f40707;
        font-size: 20px;
        font-weight: bold;
      }
      .center-price-people {
        color: #999999;
        margin-top: 5px;
        margin-left: 8px;
      }
    }
    .pre {
      display: flex;
      .pre-one {
        width: 30px;
        height: 26px;
        border: 1px solid #e1251b;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        color: #e1251b;
        line-height: 26px;
      }
      .pre-two {
        width: 78px;
        height: 26px;
        border: 1px solid #e1251b;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        color: #e1251b;
        margin-left: 10px;
        line-height: 26px;
      }
    }
  }
  // change
  .center-item2 {
    margin-right: 15px;
    box-shadow: 10px 10px 8px rgba(184, 184, 184, 0.5);
    margin-top: 20px;
    width: 204px;
    background: #ffffff;
    padding: 28px 27px 20px 27px;
    box-sizing: border-box;
    img {
      width: 150px;
      height: 150px;
    }
    .center-item-font {
      margin-top: 30px;
      width: 168px;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .center-price {
      display: flex;
      margin-top: 14px;
      // justify-content: space-between;
      .center-price-money {
        color: #f40707;
        font-size: 12px;
        margin-top: 5px;
      }
      .center-price-price {
        color: #f40707;
        font-size: 20px;
        font-weight: bold;
      }
      .center-price-people {
        color: #999999;
        margin-top: 5px;
        margin-left: 8px;
      }
    }
    .pre {
      display: flex;
      .pre-one {
        width: 30px;
        height: 26px;
        border: 1px solid #e1251b;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        color: #e1251b;
        line-height: 26px;
      }
      .pre-two {
        width: 78px;
        height: 26px;
        border: 1px solid #e1251b;
        border-radius: 4px;
        font-size: 11px;
        text-align: center;
        color: #e1251b;
        margin-left: 10px;
        line-height: 26px;
      }
    }
  }
}
.menu {
  padding-left: 22px;
  overflow: hidden;
  .outside-item {
    cursor: pointer;
    display: flex;
    margin-top: 30px;
    .title {
      font-weight: bold;
      font-size: 15px;
      &:hover {
        color: #fc4b24;
      }
    }
    .item-img {
      margin-left: 20px;
      margin-right: 17px;
    }
    .all {
      flex: 1;
      display: flex;
      border-bottom: 1px solid #eaeaea;
      padding-bottom: 15px;
      .content {
        margin-right: 35px;
        font-size: 14px;
        font-weight: 400 !important;
      }
      .titleText {
        &:hover {
          color: #fc4b24;
        }
      }
    }
  }
}
</style>